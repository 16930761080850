import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
    // sessions
    {
        path: '/',
        redirect: '/login',
        component: () => import('@/views/sessions'),
        children: [
            {
                name: 'Login',
                path: 'login',
                component: () => import('@/views/sessions/Login')
            },
            {
                name: 'LoginWithTokens',
                path: '/login/:access/:refresh',
                component: () => import('@/views/sessions/Login')
            },
            {
                // very specific use case
                name: 'LoginWithTokensRedirect',
                path: '/login/:access/:refresh/:area/:section/:id',
                component: () => import('@/views/sessions/Login')
            },
            {
                name: 'LoginClient',
                path: 'login/client',
                component: () => import('@/views/sessions/Login')
            },
            {
                name: 'LoginClientWithCoupon',
                path: 'login/client/coupon/:coupon',
                component: () => import('@/views/sessions/Login')
            },
            {
                name: 'LoginIntroducer',
                path: 'login/introducer',
                component: () => import('@/views/sessions/Login')
            },
            {
                name: 'ClientSignUp',
                path: 'client_sign_up/:code',
                component: () => import('@/views/questionnaires/ClientSignUp')
            },
            {
                name: 'IntroducerRegister',
                path: 'introducer_sign_up/:code',
                component: () => import('@/views/questionnaires/IntroducerSignUp')
            }
        ]
    },
    // other
    {
        path: '*',
        component: () => import('@/views/introducer/pages/NotFound')
    },
    // client
    {
        path: '/client',
        component: () => import('@/views/client/index'),
        children: [
            {
                name: 'ClientDashboard',
                path: 'dashboard',
                component: () => import('@/views/client/dashboard/index')
            },
            {
                name: 'ClientSettings',
                path: 'settings',
                component: () => import('@/views/client/settings/index')
            },
            {
                name: 'ClientTransactionDetail',
                path: 'transaction/:id',
                component: () => import('@/views/client/transaction/detail/index')
            },
            {
                name: 'ClientTransactionCouponDetail',
                path: 'transaction_coupon/:id',
                component: () => import('@/views/client/transaction/detail/coupon/index')
            },
            // questionnaires
            {
                name: 'ClientProfileFull',
                path: 'questionnaires/client_fact_find_full/:clientId',
                component: () => import('@/views/questionnaires/FactFindFull/index')
            },
            {
                name: 'ClientEstatePlanningFactFind',
                path: 'questionnaires/fact_find_estate_planning/:transactionId/:clientId?',
                component: () => import('@/views/questionnaires/EstatePlanningFactFind/index')
            },
            {
                name: 'ClientInsuranceFactFind',
                path: 'questionnaires/insurance_fact_find/:transactionId/:clientId',
                component: () => import('@/views/questionnaires/InsuranceFactFind/index')
            },
            {
                name: 'ClientMortgageFactFind',
                path: 'questionnaires/mortgage_fact_find/:transactionId/:clientId',
                component: () => import('@/views/questionnaires/MortgageFactFind/index')
            },
            {
                name: 'ClientFactFindEstatePlanningWillInstructions',
                path: 'questionnaires/fact_find_estate_planning_will/:transactionId/:clientId/:estatePlanningSurveyId/:willId',
                component: () => import('@/views/questionnaires/FactFindEstatePlanningWill/index')
            },
            {
                name: 'ClientMortgagePropertyInstructions',
                path: 'questionnaires/mortgage_property_instructions/:transactionId/:clientId/:mortgagePropertyId?',
                component: () => import('@/views/questionnaires/MortgageProperty/index')
            },
            {
                name: 'ClientMortgageInstructions',
                path: 'questionnaires/mortgage_instructions/:transactionId/:mortgageSurveyId?',
                component: () => import('@/views/questionnaires/MortgageSurvey/index')
            },
            {
                name: 'ClientEstatePlanningSurvey',
                path: 'questionnaires/estate_planning_survey/:transactionId/:clientId/:estatePlanningSurveyId',
                component: () => import('@/views/questionnaires/EstatePlanningSurvey/index')
            },
            {
                name: 'ClientEstatePlanningWillInstructions',
                path: 'questionnaires/estate_planning_will_instructions/:transactionId/:clientId/:willId',
                component: () => import('@/views/questionnaires/EstatePlanningWill/index')
            },
            {
                name: 'ClientEstatePlanningLpaInstructions',
                path: 'questionnaires/estate_planning_lpa_instructions/:transactionId/:clientId/:lpaId',
                component: () => import('@/views/questionnaires/LpaFull')
            },
            {
                name: 'ClientEstatePlanningStorageInstructions',
                path: 'questionnaires/storage_instructions/:clientId/:transactionId/:storageId',
                component: () => import('@/views/questionnaires/Storage')
            },
            {
                name: 'DocumentRequests',
                path: 'document_requests',
                component: () => import('@/views/questionnaires/Documents/Requests')
            },
            {
                name: 'DocumentRequestsAndSigning',
                path: 'document_requests_and_signing/:verificationMethod',
                component: () => import('@/views/questionnaires/Documents/RequestsAndSigning')
            },
            {
                name: 'ClientVaultInstructions',
                path: 'questionnaires/vault_instructions/:clientId',
                component: () => import('@/views/questionnaires/VaultFull')
            },
            {
                name: 'ClientVaultItem',
                path: 'questionnaires/vault_item/:clientId/:vaultId/:vaultItemId?',
                component: () => import('@/views/questionnaires/VaultItemFull')
            },
            {
                name: 'ClientTransactionFeedback',
                path: 'questionnaires/feedback/:transactionId',
                component: () => import('@/views/questionnaires/Feedback/index')
            },
            // simple questionnaire views: Profile
            {
                name: 'ClientEstatePlanningCouponProfileBasic',
                path: 'questionnaires/fact_find_estate_planning/simple/basic/:transactionId/:clientId?',
                component: () => import('@/views/questionnaires/EstatePlanningFactFind/simple/Basic')
            },
            {
                name: 'ClientEstatePlanningCouponProfileAdditional',
                path: 'questionnaires/fact_find_estate_planning/simple/additional/:transactionId/:clientId?',
                component: () => import('@/views/questionnaires/EstatePlanningFactFind/simple/Additional')
            },
            {
                name: 'ClientEstatePlanningCouponProfileDependants',
                path: 'questionnaires/fact_find_estate_planning/simple/dependants/:transactionId/:clientId?',
                component: () => import('@/views/questionnaires/EstatePlanningFactFind/simple/Dependants')
            },
            {
                name: 'ClientEstatePlanningCouponProfileFinancial',
                path: 'questionnaires/fact_find_estate_planning/simple/financial/:transactionId/:clientId?',
                component: () => import('@/views/questionnaires/EstatePlanningFactFind/simple/Financial')
            },
            // simple questionnaires views: Estate Planning Survey
            {
                name: 'EstatePlanningSurveyBusiness',
                path: 'questionnaires/estate_planning_survey_business/:transactionId/:clientId/:estatePlanningSurveyId',
                component: () => import('@/views/questionnaires/EstatePlanningSurvey/simple/Business')
            },
            {
                name: 'EstatePlanningSurveyDivorce',
                path: 'questionnaires/estate_planning_survey_divorce/:transactionId/:clientId/:estatePlanningSurveyId',
                component: () => import('@/views/questionnaires/EstatePlanningSurvey/simple/Divorce')
            },
            {
                name: 'EstatePlanningSurveyHealthStatus',
                path: 'questionnaires/estate_planning_survey_health_status/:transactionId/:clientId/:estatePlanningSurveyId',
                component: () => import('@/views/questionnaires/EstatePlanningSurvey/simple/HealthStatus')
            },
            {
                name: 'EstatePlanningSurveyExistingProducts',
                path: 'questionnaires/estate_planning_survey_existing_products/:transactionId/:clientId/:estatePlanningSurveyId',
                component: () => import('@/views/questionnaires/EstatePlanningSurvey/simple/ExistingProducts')
            },
            // simple questionnaire views: Will Instructions
            {
                name: 'WillSimpleTrustees',
                path: 'questionnaires/will_instructions_trustees/:transactionId/:clientId/:willId',
                component: () => import('@/views/questionnaires/WillFull/simple/Trustees')
            },
            {
                name: 'WillSimpleExecutors',
                path: 'questionnaires/will_instructions_executors/:transactionId/:clientId/:willId',
                component: () => import('@/views/questionnaires/WillFull/simple/Executors')
            },
            {
                name: 'WillSimplePets',
                path: 'questionnaires/will_instructions_pets/:transactionId/:clientId/:willId',
                component: () => import('@/views/questionnaires/WillFull/simple/Pets')
            },
            {
                name: 'WillSimpleGuardians',
                path: 'questionnaires/will_instructions_guardians/:transactionId/:clientId/:willId',
                component: () => import('@/views/questionnaires/WillFull/simple/Guardians')
            },
            {
                name: 'WillSimpleGifts',
                path: 'questionnaires/will_instructions_gifts/:transactionId/:clientId/:willId',
                component: () => import('@/views/questionnaires/WillFull/simple/Gifts')
            },
            {
                name: 'WillSimpleDistribution',
                path: 'questionnaires/will_instructions_distribution/:transactionId/:clientId/:willId',
                component: () => import('@/views/questionnaires/WillFull/simple/Distribution')
            },
            {
                name: 'WillSimpleFuneral',
                path: 'questionnaires/will_instructions_funeral/:transactionId/:clientId/:willId',
                component: () => import('@/views/questionnaires/WillFull/simple/Funeral')
            },
            // simple questionnaire views: Lpa Instructions
            {
                name: 'LpaSimpleFinancial',
                path: 'questionnaires/lpa_financial_instructions/:transactionId/:clientId/:lpaId',
                component: () => import('@/views/questionnaires/LpaFull/simple/Financial')
            },
            {
                name: 'LpaSimpleHealth',
                path: 'questionnaires/lpa_health_instructions/:transactionId/:clientId/:lpaId',
                component: () => import('@/views/questionnaires/LpaFull/simple/Health')
            },
            // checkout
            {
                name: 'TransactionCouponCheckout',
                path: 'questionnaires/checkout/:transactionId/:clientId',
                component: () => import('@/views/questionnaires/TransactionCheckout')
            },
            // feedback
            {
                name: 'ClientTransactionFeedback',
                path: 'questionnaires/feedback/:transactionId',
                component: () => import('@/views/questionnaires/Feedback/index')
            },
            // referral
            {
                name: 'ClientReferral',
                path: 'questionnaires/referral',
                component: () => import('@/views/client/dashboard/referral/index')
            },
        ]
    },
    // introducer
    {
        path: '/introducer',
        component: () => import('@/views/introducer/index'),
        children: [
            {
                name: 'IntroducerDashboard',
                path: 'dashboard',
                component: () => import('@/views/introducer/dashboard/index')
            },
            {
                name: 'IntroducerSettings',
                path: 'settings',
                component: () => import('@/views/introducer/settings/index')
            },
            // lists / tables
            {
                name: 'Transactions',
                path: 'transactions',
                component: () => import('@/views/introducer/transaction/all/index')
            },
            {
                name: 'Coupons',
                path: 'coupons',
                component: () => import('@/views/introducer/coupons/index')
            },
            {
                name: 'Statistics',
                path: 'statistics',
                component: () => import('@/views/introducer/statistics/index')
            },
            {
                name: 'TransactionsManage',
                path: 'transactions_manage',
                component: () => import('@/views/introducer/transaction/all/TransactionsManage')
            },
            {
                name: 'Clients',
                path: 'clients',
                component: () => import('@/views/introducer/client/all/index')
            },
            {
                name: 'Documents',
                path: 'documents',
                component: () => import('@/views/introducer/document/all/index')
            },
            {
                name: 'ReportCommissions',
                path: 'report/commissions',
                component: () => import('@/views/introducer/reports/commissions/index')
            },

            // details
            {
                name: 'IntroducerTransactionDetail',
                path: 'transaction/:id',
                component: () => import('@/views/introducer/transaction/detail/index')
            },
            // Learning
            {
                name: 'Learning',
                path: 'learning',
                component: () => import('@/views/introducer/learning/index')
            },
            {
                name: 'Course',
                path: 'learning/course/:id',
                component: () => import('@/views/introducer/learning/course/index')
            },

            // questionnaires
            {
                name: 'EstatePlanningFactFind',
                path: 'questionnaires/estate_planning_fact_find/:transactionId/:clientId',
                component: () => import('@/views/questionnaires/EstatePlanningFactFind/index')
            },
            {
                name: 'EstatePlanningWillInstructions',
                path: 'questionnaires/estate_planning_will_instructions/:transactionId/:clientId/:willId',
                component: () => import('@/views/questionnaires/EstatePlanningWill/index')
            },
            {
                name: 'FactFindEstatePlanningWillInstructions',
                path: 'questionnaires/fact_find_estate_planning_will/:transactionId/:clientId/:estatePlanningSurveyId/:willId',
                component: () => import('@/views/questionnaires/FactFindEstatePlanningWill/index')
            },
            {
                name: 'EstatePlanningLpaInstructions',
                path: 'questionnaires/estate_planning_lpa_instructions/:transactionId/:clientId/:lpaId',
                component: () => import('@/views/questionnaires/LpaFull')
            },
            {
                name: 'EstatePlanningSurvey',
                path: 'questionnaires/estate_planning_survey/:transactionId/:clientId/:estatePlanningSurveyId',
                component: () => import('@/views/questionnaires/EstatePlanningSurvey/index')
            },
            {
                name: 'InsuranceFactFind',
                path: 'questionnaires/insurance_fact_find/:transactionId/:clientId',
                component: () => import('@/views/questionnaires/InsuranceFactFind/index')
            },
            {
                name: 'MortgageFactFind',
                path: 'questionnaires/mortgage_fact_find/:transactionId/:clientId',
                component: () => import('@/views/questionnaires/MortgageFactFind/index')
            },
            {
                name: 'MortgageInstructions',
                path: 'questionnaires/mortgage_instructions/:transactionId/:mortgageSurveyId?',
                component: () => import('@/views/questionnaires/MortgageSurvey/index')
            },
            {
                name: 'MortgagePropertyInstructions',
                path: 'questionnaires/mortgage_property_instructions/:transactionId/:propertyId?',
                component: () => import('@/views/questionnaires/MortgageProperty/index')
            },
            {
                name: 'EstatePlanningStorageInstructions',
                path: 'questionnaires/storage_instructions/:clientId/:transactionId/:storageId',
                component: () => import('@/views/questionnaires/Storage')
            },
            {
                name: 'DocumentRequestsUpload',
                path: 'questionnaires/document_requests/:transactionId/:clientId',
                component: () => import('@/views/questionnaires/Documents/Requests')
            },
            {
                name: 'DocumentRequestsAndSigningUpload',
                path: 'questionnaires/document_requests_and_signing/:transactionId/:clientId',
                component: () => import('@/views/questionnaires/Documents/RequestsAndSigning')
            },
            // not currently used in introducer section
            {
                name: 'WillFullInstructions',
                path: 'questionnaires/will_instructions_full/:transactionId/:clientId/:willId',
                component: () => import('@/views/questionnaires/WillFull/index')
            },
            {
                name: 'WillShortInstructions',
                path: 'questionnaires/will_instructions_short/:transactionId/:clientId/:willId',
                component: () => import('@/views/questionnaires/WillShort/index')
            },
            {
                name: 'LpaFullInstructions',
                path: 'questionnaires/lpa_instructions_full/:transactionId/:clientId/:lpaId',
                component: () => import('@/views/questionnaires/LpaFull/index')
            },

        ]
    },
    // Coupon: coupon access
    {
        name: 'CouponStart',
        path: '/start/:coupon',
        component: () => import('@/views/questionnaires/Coupon/index')
    },
    // Product Role notification Landing page
    // When client person receives notification that they have been included in a product
    // they are directed here to view roles and booking, uses access request code
    {
        name: 'ClientPersonProductRole',
        path: '/product_role/:code?',
        component: () => import('@/views/pages/ProductRole/index')
    },
    // questionnaires: access request
    {
        path: '/questionnaires',
        component: () => import('@/views/questionnaires/index'),
        children: [
            {
                path: 'fact_find_full/:code?',
                component: () => import('@/views/questionnaires/FactFindFull/index')
            },
            {
                path: 'fact_find_estate_planning/:code?',
                component: () => import('@/views/questionnaires/EstatePlanningFactFind/index')
            },
            {
                path: 'fact_find_mortgage/:code',
                component: () => import('@/views/questionnaires/MortgageFactFind')
            },
            {
                path: 'fact_find_insurance/:code',
                component: () => import('@/views/questionnaires/InsuranceFactFind')
            },
            {
                path: 'estate_planning_will_instructions/:code?',
                component: () => import('@/views/questionnaires/EstatePlanningWill/index')
            },
            {
                path: 'estate_planning_survey/:code?',
                component: () => import('@/views/questionnaires/EstatePlanningSurvey/index')
            },
            {
                path: 'fact_find_estate_planning_will/:code?',
                component: () => import('@/views/questionnaires/FactFindEstatePlanningWill/index')
            },
            {
                path: 'will_instructions_full/:code?',
                component: () => import('@/views/questionnaires/WillFull/index')
            },
            {
                path: 'will_instructions_short/:code?',
                component: () => import('@/views/questionnaires/WillShort/index')
            },
            {
                path: 'lpa_instructions_full/:code?',
                component: () => import('@/views/questionnaires/LpaFull/index')
            },
            {
                path: 'mortgage_instructions_fact_find/:code',
                component: () => import('@/views/questionnaires/MortgageSurveyPlusFactFind/index')
            },
            {
                path: 'mortgage_instructions/:code?',
                component: () => import('@/views/questionnaires/MortgageSurvey/index')
            },
            {
                path: 'mortgage_property_instructions/:code?',
                component: () => import('@/views/questionnaires/MortgageProperty/index')
            },
            {
                path: 'mortgage_instructions_fact_find/:code?',
                component: () => import('@/views/questionnaires/MortgageSurveyPlusFactFind/index')
            },
            {
                path: 'storage_instructions/:code',
                component: () => import('@/views/questionnaires/Storage')
            },
            {
                path: 'document_requests/:code',
                component: () => import('@/views/questionnaires/Documents/Requests')
            },
            {
                path: 'document_signature/:code/:verificationMethod',
                component: () => import('@/views/questionnaires/Documents/Signing/index')
            },
            {
                path: 'document_requests_and_signing/:code/:verificationMethod',
                component: () => import('@/views/questionnaires/Documents/RequestsAndSigning')
            },
            {
                path: 'feedback/:code',
                component: () => import('@/views/questionnaires/Feedback/index')
            },
            {
                path: 'payment/:code',
                component: () => import ('@/views/questionnaires/Payment/index')
            },
            {
                path: 'estate_planning_appendix/:code',
                component: () => import ('@/views/questionnaires/EstatePlanningAppendix/index')
            }
        ]
    }
    ,
    // event_registration
    {
        path: '/event_registration/:eventId',
        component: () => import('@/views/questionnaires/Event/index')
    },
    // introducer sign up
    {
        path: '/introducer_register',
        component: () => import('@/views/questionnaires/IntroducerRegister/index'),
        children: [
            {
                path: 'continue',
                component: () => import('@/views/questionnaires/IntroducerRegister/index'),
            }
        ]
    },
    // conveyance quote
    {
        path: '/conveyance',
        component: () => import('@/views/questionnaires/Conveyance/index'),
    },
    // conveyance quote continue
    {
        name: 'Conveyance',
        path: '/conveyance/:code',
        component: () => import('@/views/questionnaires/Conveyance/index'),
    },
    // other
    {
        name: 'PaymentSuccess',
        path: '/payment/payment_complete',
        component: () => import('@/views/introducer/transaction/detail/payments/PaymentComplete')
    },
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
    scrollBehavior() {
        return {x: 0, y: 0};
    }
})

export default router
