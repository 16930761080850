import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store/store'
import IdealPostcodesVue from 'ideal-postcodes-vue';
import GullKit from './plugins/gull.kit';
import VueDOMPurifyHTML from 'vue-dompurify-html'

import VueScrollTo from 'vue-scrollto'

import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'

Vue.use(require('vue-moment'));
Vue.use(GullKit);
Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
Vue.use(VueScrollTo)
Vue.component("vue-perfect-scrollbar", () =>
  import("vue-perfect-scrollbar")
);
Vue.use(IdealPostcodesVue, {
  key: process.env.VUE_APP_IDEAL_POSTCODES_KEY
});
Vue.use(VueDOMPurifyHTML, {
  default: {
    USE_PROFILES: { html: true },
    FORBID_TAGS: ["button", "a"],
    FORBID_ATTR: ["href", "target"],
  },
})


// Import CSS
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import "@/assets/styles/sass/themes/konduit.scss";
import "@/assets/styles/sass/themes/common.scss";
import '@/assets/styles/css/questionnaire.css';
import '@/assets/styles/sass/themes/booking.scss';

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
