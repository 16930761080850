import state from "./state";
import getters from "./getters";
import mutations from "./mutations";
import actions from "./actions";

export default {
    getters,
    mutations,
    state,
    actions,
    strict: process.env.NODE_ENV !== "production",
};
