import Vue from 'vue';

const mutations = {
    accessCode(state, data) {
        state.accessCode = data
    },
    accessCodeDetails(state, data) {
        state.accessCodeDetails = data
    },
    questionsLoaded(state, data) {
        state.questionsLoaded = data
    },
    questionLoadError(state, data) {
        state.questionLoadError = data
    },
    questionLoadError401(state, data) {
        state.questionLoadError401 = data
    },
    client(state, data) {
        state.client = data;
    },
    family(state, data) {
        state.family = data;
    },
    people(state, data) {
        state.people = data
    },
    paymentRequest(state, data) {
        state.paymentRequest = data
    },
    personUpdate(state, data) {
        let index = state.people.findIndex(person => person.id === data.id)
        if (index >= 0) state.people[index] = data
    },
    coupon(state, data) {
        state.coupon = data
    },
    // questionnaire
    // progress
    numberOfSections(state, data) {
        state.numberOfSections = data
    },
    numberOfMinutes(state, data) {
        state.numberOfMinutes = data
    },
    progressPercentage(state, data) {
        state.progressPercentage = data
    },
    progressFieldSets(state, data) {
        state.progressFieldSets = data
    },
    questionsForm(state, data) {
        state.questionsForm = data
    },
    questionsFormFieldUpdate(state, { data, attribute }) {
        if (attribute in state.questionsForm) {
            state.questionsForm[attribute] = data
            // Vue.set(state.questionsForm, attribute, data)
        } else {
            // state.questionsForm[attribute] = data
            Vue.set(state.questionsForm, attribute, data)
        }
    },
    fieldSetsClear(state) {
        state.fieldSets = {}
    },
    fieldSetsUpdate(state, data) {
        if (!(data.attribute in state.fieldSets)) {
            Vue.set(state.fieldSets, data.attribute, data.data)
        }
    },
    fieldSetRemove(state, name) {
        if (name in state.fieldSets) {
            delete state.fieldSets[name]
        }
    },
    toastMessages(state, data) {
        state.toastMessages = data
    }
};

export default mutations;



