import axios from 'axios';
import router from './router';
import store from './store/store';

let isAlreadyFetchingAccessToken = false;

let SERVER_URL = process.env.NODE_ENV === 'production' ? process.env.VUE_APP_BACKEND_URL : 'http://127.0.0.1:8000'
let BASEURLACCESSCODE = `${SERVER_URL}/fact_find/api/`
let BASEURL = `${SERVER_URL}/introducer/api/`

export const BASEURLACCESSCODEPATH = BASEURLACCESSCODE

export const httpQuestionnaire = axios.create({
    baseURL: BASEURLACCESSCODE,
    xhrFields: {
        withCredentials: true
    },
    headers: {
        'Content-Type': 'application/json',
        // Authorization: THIS IS SET SEPARATELY ON LOGIN OR INSPECT TOKEN if a introducer
        // or set on questionnaire fetch if a client
    }
});

export const http = axios.create({
    baseURL: BASEURL,
    xhrFields: {
        withCredentials: true
    },
    headers: {
        'Content-Type': 'application/json'
        // Authorization: THIS IS SET SEPARATELY ON LOGIN OR INSPECT TOKEN
    }
});

const errorInterceptor = async (error) => {
    if (!error.response) {
        return Promise.reject(error);
    }

    switch (error.response.status) {
        case 400: // user error
            break;
        case 404: // not found
            break;
        case 401: // authentication error, logout the user
            console.log('401')

            if ('action' in error.response.data && 'error_message' in error.response.data) {
                // if the response back provides an action
                // used if coupon code has expired or already been used
                store.commit('accessCodeDetails', error.response.data)
                store.commit('questionLoadError', true)
            }

            if (!isAlreadyFetchingAccessToken) {
                console.log('401 start location', router.history._startLocation)

                isAlreadyFetchingAccessToken = true;
                await store.dispatch('inspectToken')
                isAlreadyFetchingAccessToken = false;

                if (store.getters.tokens.access) {
                    console.log('send original request')
                    // if user has not been redirected to login page then original
                    // request will get be resent here
                    // set previous request headers to newly refreshed headers
                    // then send new request
                    let originalRequest = error.config
                    originalRequest.headers[
                        'Authorization'
                        ] = `JWT ${store.getters.tokens.access}`;
                    return axios.request(originalRequest);

                } else if (store.getters.accessCode) {
                    console.log('user has an access code')
                }
            }









            store.commit(
                'loginError',
                {detail: error.response.data.detail}
            );

            break;
        case 403: // Incorrect permission
            router.push({name: 'Login'}).catch(() => {
            });
            store.commit('loginError', {detail: '403 Incorrect Permission'})
            // store.commit('removeTokens');
            break;
        default:
            console.error('server error');
    }
    return Promise.reject(error);
};

const responseInterceptor = (response) => {
    switch (response.status) {
        case 200:
            break;
        default:
            break;
    }
    return response;
};

http.interceptors.response.use(responseInterceptor, errorInterceptor);
httpQuestionnaire.interceptors.response.use(responseInterceptor, errorInterceptor)
