import VueGoodTablePlugin from "vue-good-table";
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';

export default {
    install(Vue) {
        Vue.use(VueGoodTablePlugin);
        Vue.use(DatePicker);
        // import into introducer or client layout
        // Vue.component(
        //     "large-sidebar",
        //     // The `import` function returns a Promise.
        //     () => import("../containers/layouts/largeSidebar")
        // );
        // not used
        // Vue.component(
        //     "compact-sidebar",
        //     // The `import` function returns a Promise.
        //     () => import("../containers/layouts/compactSidebar")
        // );
        Vue.component("vue-perfect-scrollbar", () =>
            import("vue-perfect-scrollbar")
        );
    },
};