// eslint-disable-next-line no-unused-vars
import state from './state';

const getters = {
    accessCode: (state) => {
        return state.accessCode
    },
    accessCodeDetails: (state) => {
        return state.accessCodeDetails
    },
    questionsLoaded: (state) => {
        return state.questionsLoaded
    },
    questionLoadError: (state) => {
        return state.questionLoadError
    },
    questionLoadError401: (state) => {
        return state.questionLoadError401
    },
    client: (state) => {
        return state.client
    },
    family: (state) => {
        return state.family
    },
    people: (state) => {
        return state.people
    },
    paymentRequest: (state) => {
        return state.paymentRequest
    },
    coupon: (state) => {
        return state.coupon
    },
    // questionnaire
    // progress
    numberOfSections: (state) => {
        return state.numberOfSections
    },
    numberOfMinutes: (state) => {
        return state.numberOfMinutes
    },
    progressPercentage: (state) => {
        return state.progressPercentage
    },
    progressFieldSets: (state) => {
        return state.progressFieldSets
    },
    questionsForm: (state) => {
        return state.questionsForm
    },
    fieldSets: (state) => {
        return state.fieldSets
    },
    toastMessages: (state) => {
        return state.toastMessages
    }
};

export default getters;
