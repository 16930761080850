const state = {
    accessCode: null,
    accessCodeDetails: null,
    questionsLoaded: false,
    questionLoadError: null,
    questionLoadError401: null,
    client: null,
    family: null,
    people: null,
    paymentRequest: null,
    coupon: null,
    // questionnaire
    numberOfSections: 0,
    numberOfMinutes: 0,
    progressPercentage: 0,
    progressFieldSets: [],
    questionsForm: {},
    fieldSets: {},
    toastMessages: [],
};

export default state;
