import jwt_decode from 'jwt-decode';

import axios from 'axios';
import router from '@/router';

import {BASEURLACCESSCODEPATH, http, httpQuestionnaire} from '@/services';

const actions = {
    async inspectToken({dispatch, getters}, noRedirect = false) {
        const tokenAccess = getters.tokens.access;
        const tokenRefresh = getters.tokens.refresh
        console.log('inspect token', tokenAccess)

        if (tokenAccess) {
            if (
                http.defaults &&
                http.defaults.headers.Authorization !== `JWT ${tokenAccess}`
            ) {
                dispatch('setIntroducerAuthorizationHeader');
                dispatch('setIntroducerClientAuthorizationHeader')
            }

            const sixMinutes = 300
            const accessDecoded = jwt_decode(tokenAccess);
            const refreshDecoded = jwt_decode(tokenRefresh)
            const accessExpiry = accessDecoded.exp;
            const refreshExpiry = refreshDecoded.exp
            const now = Date.now() / 1000;

            let accessExpired = accessExpiry - now < sixMinutes
            let refreshExpired = now > refreshExpiry

            if (accessExpired) {
                console.log('access expired')
                // access token has expired, use refresh token to renew access token
                // server access token lifetime is 6 minutes: 360 seconds
                // less than 5 minutes to go = 300 seconds
                return dispatch('refreshToken');
            } else if (refreshExpired) {
                console.log('refresh expired')
                // refresh token has expired the user needs to re login to get a new refresh token
                dispatch('logout');
            } else {
                console.log('do nothing')
                // DO NOTHING, DO NOT REFRESH
                if (!getters.user) {
                    await dispatch('userFetch');
                }

                if (!noRedirect) {

                    console.log('redirect to sign in 1')

                    router.push({name: 'Login'}).catch((error) => {
                        console.log(error);
                    });
                }


            }
        } else if (!tokenAccess && !getters.accessCode) {
            console.log('no access codes')
            return dispatch('logout');

        }
        return Promise.resolve(true);
    },
    setIntroducerAuthorizationHeader({getters}) {
        // alternative axios instance
        http.defaults.headers.Authorization = `JWT ${getters.tokens.access}`;
    },
    setIntroducerClientAuthorizationHeader({getters}) {
        // set introducer client authorization header and url: main questionnaire axios instance
        httpQuestionnaire.defaults.headers.Authorization = `JWT ${getters.tokens.access}`;
    },
    // eslint-disable-next-line no-unused-vars
    setClientAuthorizationHeader({getters}, data) {
        // set introducer client authorization header and url: Code is access code in url
        httpQuestionnaire.defaults.baseURL = BASEURLACCESSCODEPATH
        httpQuestionnaire.defaults.headers.Authorization = `Code ${data}`;
    },

    // eslint-disable-next-line no-unused-vars
    setIntroducerClientIDAuthorizationHeader({getters}, data) {
        httpQuestionnaire.defaults.params = {}

        let keys = Object.keys(data)
        for (let i in keys) {
            httpQuestionnaire.defaults.params[keys[i]] = data[keys[i]]
        }
        httpQuestionnaire.defaults.baseURL = BASEURLACCESSCODEPATH
    },

    logout({commit, dispatch}) {
        // general logout function for 405 etc

        commit('removeTokens');
        dispatch('setRedirectUrl')

        router.push({name: 'Login'}).catch((error) => {
            console.log(error);
        });

    },
    logoutIntroducer({commit}) {
        // logout function for top-bar in order to redirect back to introducer version of login page
        commit('removeTokens');

        router.push({name: 'LoginIntroducer'}).catch((error) => {
            console.log(error);
        });
    },
    logoutClient({commit}) {
        // logout function for top-bar in order to redirect back to client version of login page
        commit('removeTokens');

        router.push({name: 'LoginClient'}).catch((error) => {
            console.log(error);
        });
    },
    async _setTokens({commit, dispatch}, data) {
        console.log('set tokens', data)
        commit('accessToken', data.access);
        commit('refreshToken', data.refresh);
        commit('loginError', null);
        dispatch('setIntroducerAuthorizationHeader');
        dispatch('setIntroducerClientAuthorizationHeader')

        if (!data.noRedirect) {
            dispatch('redirectUser')
        }
        return Promise.resolve(true);
    },
    async obtainToken({commit, getters, dispatch}, data) {
        const payload = {
            email: data.email,
            password: data.password,
        };
        axios.defaults.withCredentials = true;

        await axios
            .post(getters.endpoints.obtainJWT, payload)
            .then((response) => {
                if (data.noRedirect) response.data.noRedirect = true;
                dispatch('_setTokens', response.data);
            })
            .catch((error) => {
                commit('loginError', error.response.data);
                throw error;
            });

        if (!getters.user) {
            dispatch('userFetch');
        }

    },
    async passwordReset({getters}, data) {
        const payload = {
            email: data.email
        };
        axios.defaults.withCredentials = true;

        return axios
            .post(getters.endpoints.passwordReset, payload)
            .then(() => {
                return 'Done'
            })
            .catch(() => {

            });
    },
    async refreshToken({commit, getters, dispatch}) {
        const decoded = jwt_decode(getters.tokens.refresh);
        const exp = decoded.exp;
        const now = Date.now() / 1000;

        if (!getters.tokens.refresh || exp - now <= 0) {
            dispatch('logout');
            return;
        }

        const payload = {
            refresh: getters.tokens.refresh
        };

        return axios
            .post(getters.endpoints.refreshJWT, payload)
            .then((response) => {
                commit('accessToken', response.data.access);
                dispatch('setIntroducerAuthorizationHeader');
                dispatch('setIntroducerClientAuthorizationHeader')

                // eslint-disable-next-line no-useless-catch
                try {
                    if (!getters.user) dispatch('userFetch');
                } catch (error) {
                    throw error;
                }
                return Promise.resolve(true);
            })
            .catch(() => {
                return Promise.resolve(false);
            });
    },
    async userFetch({commit}) {
        return http.get('user_current')
            .then((response) => {
                commit('user', response.data);
                return Promise.resolve()
            })
            .catch(() => {
                return Promise.reject()
            });
    },
    async setRedirectUrl({commit}) {
        // do not reroute if already on a login route

        if (!router.history._startLocation.includes('login')) {
            commit('loginRedirectPage', router.history._startLocation)
        }
    },
    async redirectUser({getters, dispatch, commit}, redirectUrl) {
        console.log('redirect user')
        // redirects user to either dashboard or previous page before login
        // guard
        if (!getters.user) {
            await dispatch('userFetch')
        }

        let redirect = null

        if (redirectUrl) {
            redirect = redirectUrl
        } else if (getters.loginRedirectPage) {
            // goto redirect url if set
            redirect = getters.loginRedirectPage
        } else if (getters.user.is_introducer) {
            redirect = {
                name: 'IntroducerDashboard'
            }
        } else if (getters.user.is_client) {
            redirect = {
                name: 'ClientDashboard'
            }
        }
        // go to redirect
        router
            .push(redirect)
            .catch((error) => {
                console.log(error);
            });
        // clear redirect url
        commit('loginRedirectPage', null)
    }
};

export default actions;
