import Vuex from 'vuex';
import Vue from 'vue';

import largeSidebar from './gull/largeSidebar';
import compactSidebar from './gull/compactSidebar';
import config from './gull/config';
import authentication from './authentication/store';
import questionnaires from './questionnaires/store'
import VuexPersistence from 'vuex-persist';

const vuexLocal = new VuexPersistence({
    storage: window.localStorage,

    reducer: (state) => ({
        authentication: {
            tokens: state.authentication.tokens
        }
        // add more persistent storage here
    })
});

Vue.use(Vuex);

export default new Vuex.Store({
    plugins: [vuexLocal.plugin],
    modules: {
        largeSidebar,
        compactSidebar,
        config,
        authentication,
        questionnaires
    }
});
