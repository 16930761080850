// eslint-disable-next-line no-unused-vars
import state from './state';

const getters = {
    endpoints: (state) => state.endpoints,
    tokens: (state) => {
        return state.tokens;
    },
    loginError: (state) => {
        return state.loginError;
    },
    user: (state) => {
        return state.user;
    },
    networkError: (state) => {
        return state.networkError;
    },
    hostUrl: (state) => {
        return state.host;
    },
    hostBaseUrl: (state) => {
        return state.host.replace(/^https?:\/\//, '');
    },
    loginRedirectPage: (state) => {
        return state.loginRedirectPage
    }
};

export default getters;
