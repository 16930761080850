import { http } from '@/services';

const mutations = {
    // current logged in user
    user(state, data) {
        state.user = data;
    },
    // authentication
    loginError(state, message) {
        state.loginError = message;
    },
    networkError(state, message) {
        state.networkError = message;
    },
    accessToken(state, newToken) {
        state.tokens.access = newToken;
    },
    refreshToken(state, newToken) {
        state.tokens.refresh = newToken;
    },
    removeTokens(state) {
        // remove all authentication tokens
        // reset http auth header JWT or Access Code
        http.defaults.headers.Authorization = null;
        // remove jwt for introducer or client
        state.tokens.access = null;
        state.tokens.refresh = null;
        // remove client access codes from questionnaire state module
        // below require 'this' for access to another state module
        this.state.questionnaires.accessCode = null
        this.state.questionnaires.accessCodeDetails = null
        // remove user
        state.user = null
    },
    loginRedirectPage(state, url) {
        state.loginRedirectPage = url
    }
};

export default mutations;
