import {httpQuestionnaire} from '@/services';
import Vue from "vue";

const actions = {
    questionFormRemoveAttribute({state}, attribute) {
        Vue.delete(state.questionsForm, attribute)
    },
    peopleFetch({state, commit}) {
        let params = {
            id: state.client.id,
            access_code: state.accessCode
        }
        httpQuestionnaire.get('client_person', {params: params}).then(
            response => {
                commit('people', response.data)
            }
        ).catch(
            error => {
                console.log(error)
            }
        )
    },
    resetQuestionnaire({state}) {
        state.questionsLoaded = false
        state.questionLoadError = null
        state.questionLoadError401 = null
        state.client = null
        state.family = null
        state.people =null
        state.numberOfSections = 0
        state.numberOfMinutes = 0
        state.progressPercentage = 0
        state.progressFieldSets = []
        state.questionsForm = {}
        state.fieldSets = {}
    },
    removeAccessCode({state}) {
        state.accessCode = null
        state.accessCodeDetails = null
    }
};

export default actions;
