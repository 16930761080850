const state = {
    tokens: {
        access: null,
        refresh: null
    },
    user: null,
    loginError: null,
    networkError: null,
    host: process.env.VUE_APP_BACKEND_URL,
    endpoints: {
        obtainJWT: `${process.env.VUE_APP_BACKEND_URL}/api/token/`,
        refreshJWT: `${process.env.VUE_APP_BACKEND_URL}/api/token/refresh/`,
        passwordReset: `${process.env.VUE_APP_BACKEND_URL}/accounts/password_reset`
    },
    loginRedirectPage: null
};

export default state;
